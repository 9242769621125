/* body {
    background-image: url("../images/background.jpeg");
    background-color: white;
    background-size: cover;
    background-position: center;
    height: 700px;
    width: 100%;
    z-index: 1; */


.content{
color: white;
/* font-size: large; */
font-weight: 400;
font-size: 3rem;
font-family: 'Roboto', sans-serif;
padding: 100px 0 0 0;
}




.text1{
    color: black;
    /* color: #38127fc1; */
    font-size: larger;
    text-align: center;
    
    font-weight: 400;
    font-family:Verdana, Geneva, Tahoma, sans-serif
}
.filler{
    margin-top:130px;
}

hr{
    width:90%; margin:auto !important;
}

.slider{
    margin: 0 20px 0 0;
    height: 120px;
}


