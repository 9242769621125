  .brand{
    color: white;
    font-size: larger;
    font-weight: 900;
    font-family:Verdana, Geneva, Tahoma, sans-serif
  }

  
  
  .navbar {
    background-color:rgba(0, 0, 0, 0.681);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    color: white;
  }

  .navbar-container {
    display: flex;
    align-items: center;
  }
  .navbar-item {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    margin-right: 30px;
  }

  .navbar-logo {
    width: 50px;
    height: 50px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
    outline: none;
  }

  .nav-stick{
    width: 100%;
    height: 100px;
    background-color: rgb(231, 49, 116);
    text-align: center;
    position:fixed;
    top:0;
    z-index: 20;
    margin: 0;
    
  }
  .disp{
    display: inline-block;
  }
  .disp-content{
    margin: 10px;
    text-decoration: none;
    color: white;

  }
  .im{
    width: 20px;
    height: 20px;
  }
  a {
    color: inherit;
    text-decoration: none;
    color: white;
  }
  a:link {
    text-decoration: none;
    color: white;
  }
  a:hover{
    text-decoration: none;
    color: white;
  }

  .nav-items-content{
    color: rgb(60, 5, 81);
    /* font-family: 'Caveat Brush', cursive; */
    /* font-weight: 900; */
    font-size: 30px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Suwannaphum', serif; 
  }
  p{
    margin-bottom: 0;
  }



  @media (min-width: 1000px) {
    .nav-stick {
      height: 100px;
    }
  }



    .brochure:hover {
      cursor:pointer;
      
  
  }

  .hand:hover {
    cursor:pointer;
   

}