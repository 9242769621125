.profile{
    width: 50%;
    height: auto;
    aspect-ratio: 1/1 auto;
    /* border-radius: 50%;  */
    /* border: 2px solid #000;  */
    background-color: #FFF;
    padding: 50px;
}

/* .row{
    margin: 50px;
} */

.pa{
    text-align: justify;
    padding: 50px;
}

.con{
    padding: 10% 0 0 0;
}

