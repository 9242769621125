/* styles.css */

.circle-image {
    
    animation: levitate 2s infinite alternate ease-in-out; /* Apply the levitation animation */

  }
  @keyframes levitate {
    0% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(-50%, -50%) translateY(-10px); /* Adjust the vertical movement */
    }
  }
  
 
@media (min-width: 768px) {
    .circle-image {
      border: 2px solid rgba(255, 0, 0, 0.281); /* Add a blue border on larger screens */
      width: 16vw !important;
     
    }
  }
  
  
  .floating-div {
    position: fixed;
    top: 50%;
    left: -200px; /* Position it off the left side of the screen */
    padding: 10px;
    background-color: blue;
    color: white;
    transform: translateY(-50%);
    animation: slideIn 1s forwards;
  }
  


  .floating-div {
    position: fixed;
    top: 50%;
    left: -300px; /* Position it off the left side of the screen */
    width: 300px;
    padding: 10px;
    background-color: blue;
    color: white;
    transform: translateY(-50%);
    animation: slideIn 1s forwards;
    transition: left 0.5s ease-in-out; /* Add a smooth transition effect */
  }
  
  .floating-div.show {
    left: calc(50% - 150px); /* Center the div horizontally on the screen */
  }
